import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AppHeader from '../appHeader/appHeader';
import AppMenu from '../appMenu/appMenu';
import AppFooter from '../appFooter/appFooter';
import AppSetting from '../appSetting/appSetting';
import AppDashboard from '../appDashboard/appDashboard';
import AppAdmin from '../appMenu/appAdmin';
import Cookies from 'js-cookie';

function Home() {
  const token = Cookies.get('ANers');
  const [authenticated, setAuthenticated] = useState(false);
  const [resp, setResp] = useState(false);

  useEffect(() => {

    axios.post('/api/auth', {token}, {
      withCredentials: true,
    })
      .then((response) => {
        if (response.data.status === 200) {
          setAuthenticated(true);
	  setResp(response.data.user);
        } else {
	  //window.alert(response.data.status)
          window.location = '/login';
        }
      })
      .catch((err) => {
	//window.alert(err)
        window.location = '/login';
      });
  }, []);

  if (!authenticated) {
    return <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh"
    }}>
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>;
  }

	if(resp.id == 1){
	  return (
	    <>
	      <AppHeader response={resp} />
	      <AppAdmin/>
	      <AppDashboard/>
	      <AppFooter/>
	      <AppSetting/>
	    </>
	  )	

	}else{
  return (
    <>
      <AppHeader response={resp} />
      <AppMenu />
      <AppDashboard />
      <AppFooter />
      <AppSetting />
    </>  
  );
}}

export default Home;
